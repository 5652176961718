// auth.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { SiteService } from 'src/app/core/services/APIs/site/site.service';
import { loadSiteSuccess, loadSiteTypeSuccess, loadSiteTypesSuccess, loadSites, loadSitesTypeSuccess } from '../actions/site.actions';
import { Store } from '@ngrx/store';
import { AddAll, transformPayload } from 'src/app/core/utils/array-utils';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { selectCurrentLanguage } from '../selectors/language.selectors';
import { AppState } from '../app.store';

@Injectable()
export class SiteEffects {
    skip = 0;
    limit = 250;
    constructor(private actions$: Actions,
        private store: Store<AppState>,
        private siteService: SiteService,
        private router: Router,
        private translateService: TranslateService) { }

    loadRoles$ = createEffect(
        () => this.actions$.pipe(
            ofType(loadSites),
            tap(() => {
                // Check localStorage first
                const storedSites = localStorage.getItem('sites');
                const storedSite = localStorage.getItem('site');
                const storedTypes = localStorage.getItem('types');
                const storedType = localStorage.getItem('type');

                if (storedSites && storedSite && storedTypes && storedType) {
                    // If all required data is in localStorage, dispatch from there
                    try {
                        const sites = JSON.parse(storedSites).sites;
                        const site = JSON.parse(storedSite).site;
                        const typesSite = JSON.parse(storedTypes).typesSite;
                        const typeSite = JSON.parse(storedType).typeSite;

                        // Translate sites before dispatching
                        const translatedSites = sites.map((site: any) => {
                            if (site.siteId === 'all') {
                                return {
                                    ...site,

                                    name: this.translateService.instant('COMMON.ALL')
                                };
                            }
                            return site;
                        });

                        // Translate single site if needed
                        let translatedSite = site;
                        if (site.siteId === 'all') {
                            translatedSite = {
                                ...site,
                                name: this.translateService.instant('COMMON.ALL')
                            };
                        }

                        this.store.dispatch(loadSitesTypeSuccess({ sites: translatedSites }));
                        this.store.dispatch(loadSiteSuccess({ site: translatedSite }));
                        this.store.dispatch(loadSiteTypesSuccess({ typesSite }));
                        this.store.dispatch(loadSiteTypeSuccess({ typeSite }));

                        return;
                    } catch (error) {
                        console.error('Error parsing stored data', error);
                    }
                }

                // If not in localStorage or parsing failed, fetch from API
                this.siteService.getSites({ limit: this.limit, skip: this.skip })
                    .pipe(
                        map((sites: any) => {
                            const siteData = sites?.data || sites;
                            // Translate sites from API
                            return siteData.map((site: any) => {
                                if (site.siteId === 'all') {
                                    return {
                                        ...site,
                                        name: this.translateService.instant('COMMON.ALL')
                                    };
                                }
                                return site;
                            });
                        }),
                        catchError(() => of({ type: '[Site] Load Sites Failed' }))
                    )
                    .subscribe((sites: any) => {
                        this.store.dispatch(loadSitesTypeSuccess({ sites: sites }));
                    });
            })
        ),
        { dispatch: false }
    );
    persistSites$ = createEffect(()=>
        this.actions$.pipe(
            ofType(loadSitesTypeSuccess),
            tap(({sites})=>{
                // Translate 'all' site if it exists in the array
                const translatedSites = sites.map(site => {
                    if (site.siteId === 'all') {
                        return {
                            ...site,
                            name: this.translateService.instant('COMMON.ALL')
                        };
                    }
                    return site;
                });
                localStorage.setItem('sites', JSON.stringify({ sites: translatedSites }));
                
                // Check if there's an existing site in localStorage
                const storedSite = localStorage.getItem('site');
                if (storedSite) {
                    const existingSite = JSON.parse(storedSite).site;
                    // Find the site in the new translated sites array
                    const matchingSite = translatedSites.find(site => site.siteId === existingSite.siteId);
                    if (matchingSite) {
                        this.store.dispatch(loadSiteSuccess({ site: matchingSite }));
                        return;
                    }
                }
                // If no stored site or matching site found, default to first site
                this.store.dispatch(loadSiteSuccess({ site: translatedSites[0] })); 
            })
        ),
        { dispatch: false }
    )
    persistSite$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSiteSuccess),
                tap(({ site }) => { 
                    // If siteId is 'all', translate the name before storing
                    if (site.siteId === 'all') {
                        const translatedSite = {
                            ...site,
                            name: this.translateService.instant('COMMON.ALL')
                        };
                        localStorage.setItem('site', JSON.stringify({ site: translatedSite })); 
                        localStorage.removeItem('types');
                        localStorage.removeItem('type');
                        this.router.navigate(['/dashboard/organizations']);
                        return;
                    }
                    
                    localStorage.setItem('site', JSON.stringify({ site })); 
                    let arrayOfTypes = transformPayload(site.registeredServices);
                    this.store.dispatch(loadSiteTypesSuccess({typesSite:arrayOfTypes}))
                })
            ),
        { dispatch: false }
    );
    persistTypes$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSiteTypesSuccess),
                tap(({ typesSite}) => {
                    localStorage.setItem('types', JSON.stringify({ typesSite })); 
                    
                    // Check if type is already in local storage
                    const storedType = localStorage.getItem('type');
                    const parsedStoredType = storedType ? JSON.parse(storedType).typeSite : null;
                    
                    if (!storedType || !typesSite.includes(parsedStoredType)) {
                        // Dispatch first type if there's no stored type or if stored type isn't in typesSite
                        this.store.dispatch(loadSiteTypeSuccess({typeSite: typesSite[0]}))
                    }
                })
            ),
        { dispatch: false }
    );
    persistType$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(loadSiteTypeSuccess),
                tap(({ typeSite}) => {
                    localStorage.setItem('type', JSON.stringify({ typeSite })); 
                })
            ),
        { dispatch: false }
    );

    // Update language change effect to handle both sites and site
    updateAllSiteOnLanguageChange$ = createEffect(
        () => this.store.select(selectCurrentLanguage).pipe(
            tap((language) => {
                // Handle single site
                const storedSite = localStorage.getItem('site');
                if (storedSite) {
                    const site = JSON.parse(storedSite).site;
                    if (site.siteId === 'all') {
                        const translatedSite = {
                            ...site,
                            name: this.translateService.instant('COMMON.ALL')
                        };
                        localStorage.setItem('site', JSON.stringify({ site: translatedSite }));
                        this.store.dispatch(loadSiteSuccess({ site: translatedSite }));
                    }
                }

                // Handle sites array
                const storedSites = localStorage.getItem('sites');
                if (storedSites) {
                    const sites = JSON.parse(storedSites).sites;
                    const translatedSites = sites.map((site: any) => {
                        if (site.siteId === 'all') {
                            return {
                                ...site,
                                name: this.translateService.instant('COMMON.ALL')
                            };
                        }
                        return site;
                    });
                    localStorage.setItem('sites', JSON.stringify({ sites: translatedSites }));
                    this.store.dispatch(loadSitesTypeSuccess({ sites: translatedSites }));
                }
            })
        ),
        { dispatch: false }
    );
}
